import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { navigate } from 'gatsby';
import { submitPayment } from '../../services/payment.service';
import logAnalyticsEvent from '../../utils/log-analytics-event';
import { usePaymentMethod } from '../../store';
// import { CircularProgress } from '@mui/material';

const PAYMENT_API_KEY = process.env.GATSBY_PAYMENT_API_KEY;

function PaymentIframe({ caseID, orderID, lastName }) {
  // we need a ref to be able to pass the key attribute to the dom.
  // if we pass it via JSX it considers it as a react.key and its not forwarded to the dom
  const divElement = useRef(null);
  const { setPaymentMethod } = usePaymentMethod();
  const [paymentResult, setPaymentResult] = useState(null);

  const onSubmit = async (event) => {
    const { method, token } = event.detail;
    try {
      const requestBody = {
        caseID: caseID,
        orderID: orderID,
        paymentMethod: method,
        paymentToken: token,
      };
      await submitPayment(requestBody);
      logAnalyticsEvent('payment_method_form_submit_success', {
        paymentMethod: method,
        claimId: orderID,
        caseId: caseID,
      });
      setPaymentMethod(method);
      navigate('/results');
    } catch (error) {
      logAnalyticsEvent('payment_method_form_submit_error', {
        caseID: caseID,
        claimID: orderID,
      });
    }
  };

  if (paymentResult) {
    onSubmit(paymentResult);
  }

  useEffect(() => {
    document.addEventListener('paymentSubmit', onSubmit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    if (divElement.current)
      divElement.current.setAttribute('key', PAYMENT_API_KEY);
    const sourceScriptTag = document.createElement('script');
    sourceScriptTag.id = 'dst-payment-source';
    sourceScriptTag.src =
      'https://content.digitaldisbursements.com/v1.4.3/assets/host.js';
    document.body.appendChild(sourceScriptTag);

    const textScriptTag = document.createElement('script');
    textScriptTag.type = 'text/javascript';
    textScriptTag.id = 'dst-text-script';
    textScriptTag.innerHTML = `
                        dstPaymentForm(
                            document.getElementById('dst-payment'),
                            {
                                cid:'${orderID}',
                                lastName: "${lastName}",
                                verify: false,
                                onSubmitted: ${(values) => {
                                  const paymentEvent = new CustomEvent(
                                    'paymentSubmit',
                                    { detail: values }
                                  );
                                  document.dispatchEvent(paymentEvent);
                                }}
                            }
                        )
                    `;
    sourceScriptTag.onload = () => {
      document.body.appendChild(textScriptTag);
    };

    return () => {
      const textScript = document.getElementById('dst-text-script');
      if (textScript) document.body.removeChild(textScript);

      const srcScript = document.getElementById('dst-payment-source');
      if (srcScript) document.body.removeChild(srcScript);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* {loading && <CircularProgress color="inherit" />} */}
      <div ref={divElement} id="dst-payment" />
    </>
  );
}

export default React.memo(PaymentIframe);
