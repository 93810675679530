import { useFormik } from 'formik';
import { navigate } from 'gatsby-link';
import React, { useState } from 'react';
import {
  Card,
  Grid,
  Paper,
  TextField,
  IconButton,
  Typography,
  InputAdornment,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Alert from '../alert';
import { signIn } from '../../hooks/useFirebase';
import { useClaimStatus, usePaymentMethod } from '../../store';

const SignInForm = ({
  idFieldName,
  secretFieldName,
  caseId,
  navigateTo,
  openSnackbar,
}) => {
  const [showPIN, setShowPIN] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const { clearClaimStatus } = useClaimStatus();
  const { clearPaymentMethod } = usePaymentMethod();

  const handleAuthenticationError = (errorMessage) => {
    switch (errorMessage) {
      case 'Firebase: Error (auth/too-many-requests).':
        return 'Too many attempts!';
      case 'Firebase: Error (auth/internal-error)':
        return 'An internal error has occurred!';
      case 'Firebase: Error (auth/invalid-password)':
        return 'Invalid password!';

      default:
        return 'Please enter a valid ClaimantID and PIN.';
    }
  };

  const formik = useFormik({
    initialValues: {
      [idFieldName]: '',
      [secretFieldName]: '',
    },
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const newEmail = `${values[idFieldName]}${caseId}@claimscore.ai`;
        clearClaimStatus();
        clearPaymentMethod();
        await signIn(newEmail, values[secretFieldName]);
        return navigate(navigateTo);
      } catch (e) {
        openSnackbar(handleAuthenticationError(e.message), 'error');
      } finally {
        setLoading(false);
      }
    },
  });

  const handleClickShowPIN = () => setShowPIN((show) => !show);

  return (
    <Grid item xs={12}>
      <Card>
        <form onSubmit={formik.handleSubmit}>
          <Alert
            message={`Invalid ${idFieldName} or ${secretFieldName}!`}
            type="error"
            setOpen={setShowAlert}
            open={showAlert}
          />
          <Paper
            elevation={0}
            sx={{ p: '24px', backgroundColor: '#FFFFFF', gap: '16px' }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontStyle: 'normal',
                    fontSize: '24px',
                    lineHeight: '100%',
                    mb: 1,
                    margin: 1,
                  }}
                >
                  Sign in
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id={`outlined-${idFieldName}`}
                  label={idFieldName}
                  name={idFieldName}
                  variant="outlined"
                  value={formik.values[idFieldName]}
                  onChange={(event) => {
                    const trimmedValue = event.target.value.trim();
                    const eventCopy = { ...event };
                    eventCopy.target.value = trimmedValue;
                    formik.handleChange(eventCopy);
                  }}
                  sx={{ marginBottom: '-16px' }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={Boolean(
                    formik.touched[secretFieldName] &&
                      formik.errors[secretFieldName]
                  )}
                  fullWidth
                  helperText={
                    formik.touched[secretFieldName] &&
                    formik.errors[secretFieldName]
                  }
                  id={`outlined-${secretFieldName}`}
                  type={showPIN ? 'password' : 'text'}
                  label={secretFieldName}
                  margin="normal"
                  name={secretFieldName}
                  onBlur={formik.handleBlur}
                  onChange={(event) => {
                    const trimmedValue = event.target.value.trim();
                    const eventCopy = { ...event };
                    eventCopy.target.value = trimmedValue;
                    formik.handleChange(eventCopy);
                  }}
                  value={formik.values[secretFieldName]}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle PIN visibility"
                          onClick={handleClickShowPIN}
                          edge="end"
                        >
                          {showPIN ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <LoadingButton
                  key="SignInForm"
                  sx={{ px: 9, py: 2, width: { xs: '100%' } }}
                  color="primary"
                  type="submit"
                  variant="contained"
                  loading={loading}
                >
                  <Typography>Sign In</Typography>
                </LoadingButton>
              </Grid>
            </Grid>
          </Paper>
        </form>
      </Card>
    </Grid>
  );
};

export default SignInForm;
